import * as React from "react"
import Header from "../../components/header"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import CanvasChoose from "../../components/choose-canvas"

import next from "../../images/arrow.png"

const Rodeo = () => {
  const [mounted, setMounted] = React.useState(false)
  const { t } = useTranslation()
  const { language } = useI18next()

  return (
    <div className="relative flex flex-col items-center justify-center h-screen sm:max-h-screen bg-black">
      <Header location={{ pathname: "rodeo" }} t={t} />
      {language === "en" ? (
        <StaticImage
          src={"../../images/rodeoenglish.png"}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            objectFit: "contain",
          }}
          alt="Rodeo English image"
          width={3700}
          height={3700}
          objectFit="contain"
          objectPosition="center"
          quality={90}
          onLoad={() => {
            setMounted(true)
          }}
        />
      ) : (
        <StaticImage
          src={"../../images/rodeospanish.png"}
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            objectFit: "contain",
          }}
          alt="Rodeo Spanish image"
          width={3700}
          height={3700}
          objectFit="contain"
          objectPosition="center"
          quality={90}
          onLoad={() => {
            setMounted(true)
          }}
        />
      )}
      {mounted && (
        <div className="pt-20 h-screen flex flex-col">
          <div className="flex-1 flex justify-center items-center z-10">
            <h1 className="text-white text-center font-bold text-3xl md:text-4xl">
              {t("rodeo")}
            </h1>
          </div>
          <div className="flex-[3] w-full relative flex flex-col justify-center items-center">
            <CanvasChoose />
            <div className="w-full h-full absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
              <Link to="/rodeo/1" className="pointer-events-auto">
                <img
                  src={next}
                  width={50}
                  height={50}
                  alt="Twitter Icon"
                  className="rotate-180 select-none"
                />
              </Link>
              <h2 className="text-white text-center font-bold text-lg md:text-xl">
                Pajarito
              </h2>
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
      )}
    </div>
  )
}

export default Rodeo

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
