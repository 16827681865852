import React, { useRef } from "react"

import { useLoader } from "@react-three/fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

export const Model = props => {
  const group = useRef()
  const gltf = useLoader(GLTFLoader, "/newbull.glb")
  const { scene, animations } = gltf

  return scene && props.show ? (
    <mesh transparent position={[0, -2, 0]}>
      <group ref={group} {...props} dispose={null}>
        <primitive object={scene} />
      </group>
    </mesh>
  ) : null
}
